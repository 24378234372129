<template>
  <v-tooltip top content-class="ap-tooltip" color="white" :open-delay="200">
    <template #activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot>
          {{ text }}
        </slot>
      </span>
    </template>
    <slot name="caption">
      <span class="ap-dark-gray--text text-caption">
        {{ caption || text }}
      </span>
    </slot>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    caption: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.ap-tooltip {
  opacity: 1 !important;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
}
</style>
