<template>
  <v-card class="pa-4">
    <v-row no-gutters class="mb-4" align="center" justify-md="space-between">
      <v-col cols="12" md="1" lg="1">
        <CardHeader title="Jobs" />
      </v-col>

      <v-col
        cols="12"
        md="12"
        lg="4"
        order="2"
        order-lg="1"
        class="mt-2 mt-lg-0"
      >
        <ApAutocomplete
          v-model="searchString"
          placeholder="Search by Job ID or Job Owner"
          :is-loading="isLoading"
          @input="fetchJobs"
        />
      </v-col>

      <v-col
        cols="12"
        md="11"
        lg="7"
        order="1"
        order-lg="2"
        class="d-flex flex-wrap justify-end align-end flex-sm-row mt-2 mt-md-0"
      >
        <div class="d-flex align-center mt-2 mt-md-0">
          <div
            v-if="lastRequestTime"
            class="px-3 text-caption ap-dark-gray--text"
          >
            <div>Last update:</div>
            <div>Today, {{ lastRequestTime }}</div>
          </div>
          <v-btn outlined color="primary" height="40" @click="fetchJobs">
            <v-icon left> $mdi-update </v-icon>
            Refresh
          </v-btn>
        </div>

        <div class="ml-2 mt-2 mt-md-0">
          <ApMenuFilters
            v-model="filtersSelected"
            outlined
            left-icon="$mdi-filter-outline"
            color="primary"
            label="Filter"
            height="40"
            :items="filters"
            @changed="fetchJobs"
          />
        </div>

        <v-btn
          outlined
          color="primary"
          height="40"
          class="ml-2 mt-2 mt-md-0"
          :loading="isDeleting"
          @click="deleteJobs"
        >
          <v-icon left> $mdi-delete-outline </v-icon>
          Delete
        </v-btn>

        <v-btn
          outlined
          color="primary"
          height="40"
          class="ml-2 mt-2 mt-md-0"
          @click="retryAllProcessingJobs"
        >
          <v-icon left>$mdi-repeat</v-icon>
          Retry all
        </v-btn>
      </v-col>
    </v-row>

    <ApSimpleTable
      :headers="headers"
      :items="jobsToTheTable"
      :items-per-page="jobsPerPage"
      :page="page"
      :total-pages="jobsTotalPages"
      :total-items="jobsTotalItems"
      item-designation="jobs"
      @download="onDownload"
      @page="onPageChanged"
      @repeat="retryProcessingJob"
    />
  </v-card>
</template>

<script>
import ApSimpleTable from '@/components/common/ApSimpleTable'
import ApAutocomplete from '@/components/common/ApAutocomplete'
import ApMenuFilters from '@/components/common/ApMenuFilters'
import CardHeader from '@/components/shared/CardHeader'
import { mapState, mapActions } from 'vuex'
import { debounce } from 'lodash-es'
import { format } from 'date-fns'
import {
  jobsStatusColors,
  jobsStatusIcons,
  downloadJobData,
  deleteCompletedJobs,
  retryAllProcessingJobs,
  retryProcessingJob,
} from '@/api/dupe-sets/jobs'

export default {
  components: {
    ApSimpleTable,
    ApAutocomplete,
    ApMenuFilters,
    CardHeader,
  },
  data() {
    return {
      page: 1,
      lastRequestTime: null,
      isLoading: true,
      jobIdsDownloading: [],
      searchString: '',
      // TODO: understand if the values of the
      // filters will be provided by the API
      filters: [
        {
          code: 'type',
          label: 'Job type:',
          values: [
            { code: 'bulkmerge', label: 'Bulk Merge' },
            { code: 'extraction', label: 'Extraction' },
          ],
        },
        {
          code: 'status',
          label: 'Status:',
          values: [
            { code: 'processing', label: 'Processing' },
            { code: 'pending', label: 'Pending' },
            { code: 'finished', label: 'Finished' },
            { code: 'error', label: 'Error' },
          ],
        },
      ],
      filtersSelected: {
        type: [],
        status: [],
      },
      isDeleting: false,
    }
  },
  computed: {
    ...mapState('dashboard', [
      'jobs',
      'jobsPerPage',
      'jobsTotalItems',
      'jobsTotalPages',
    ]),
    headers() {
      const headerItems = [
        { prop: 'id', label: 'Job ID' },
        { prop: 'statusCode', label: 'Status' },
        { prop: 'typeCode', label: 'Job type' },
        { prop: 'elapsedTime', label: 'Elapsed time' },
        { prop: 'initiatedAtDateTime', label: 'Date' },
        { prop: 'ownerName', label: 'Owner' },
        { prop: 'errors', label: 'Errors' },
      ]

      if (this.jobs.find((el) => el.isStatusProcessingTooLong)) {
        return [
          ...headerItems,
          {
            prop: 'actions',
            label: 'Actions',
          },
        ]
      }

      return headerItems
    },
    jobsToTheTable() {
      return this.jobs?.map((job) => {
        if (job.isTypeBulkMerge) {
          job = {
            ...job,
            hoverText: 'View details',
            to: {
              name: 'root/system-performance/jobs/details',
              params: { jobId: job.id },
            },
          }
        } else if (job.isTypeExtraction) {
          if (job.isStatusFinished) {
            job = {
              ...job,
              hoverText: 'Download ' + job.formatCode.toUpperCase(),
              event: 'download',
              isLoading: this.jobIdsDownloading.includes(job.id),
              loadingText: 'Extracting...',
            }
          } else if (job.isStatusProcessing || job.isStatusPending) {
            job = {
              ...job,
              hoverText: 'File available once extraction ends',
              isDisabled: true,
            }
          }
        }

        return {
          ...job,
          values: this.headers.map((header) => {
            switch (header.prop) {
              case 'id':
                return {
                  value: job[header.prop],
                  colorClass: 'primary--text',
                }
              case 'statusCode':
                return {
                  value: job[header.prop],
                  isTypeIcon: true,
                  icon: jobsStatusIcons[job.statusCode],
                  color: jobsStatusColors[job.statusCode],
                }
              case 'actions':
                return this.getJobActions(job)
              default:
                return {
                  value: job[header.prop],
                }
            }
          }),
        }
      })
    },
  },
  created() {
    this.fetchJobs()
  },
  methods: {
    ...mapActions('dashboard', ['getJobs']),
    onPageChanged(page) {
      this.page = page
      this.fetchJobs()
    },
    async onDownload(item) {
      this.jobIdsDownloading.push(item.id)
      await downloadJobData(item.id, item.formatCode)
      this.jobIdsDownloading = this.jobIdsDownloading.filter(
        (el) => el !== item.id
      )
    },
    fetchJobs: debounce(async function () {
      this.isLoading = true
      this.lastRequestTime = format(new Date(), 'p')
      await this.getJobs({
        page: this.page,
        searchString: this.searchString,
        status: this.filtersSelected.status,
        type: this.filtersSelected.type,
      })
      this.isLoading = false
    }, 200),
    // Check current page jobs and set Actions column
    async deleteJobs() {
      this.isDeleting = true
      await deleteCompletedJobs()
      this.isDeleting = false
      this.fetchJobs()
    },
    async retryAllProcessingJobs() {
      await retryAllProcessingJobs()
      this.fetchJobs()
    },
    async retryProcessingJob(job) {
      await retryProcessingJob(job.id)
      this.fetchJobs()
    },
    getJobActions(job) {
      const actions = []

      if (job.isStatusProcessingTooLong) {
        actions.push({
          event: 'repeat',
          icon: '$mdi-repeat',
          color: 'error',
        })
      }

      if (actions.length > 0) {
        return {
          isTypeAction: true,
          value: actions,
        }
      }

      return {}
    },
  },
}
</script>
