<template>
  <ApMenu v-bind="$props" :label="label" :left-icon="leftIcon">
    <v-list v-for="group in items" :key="group.code" dense class="pa-0">
      <v-subheader v-if="group.label" class="px-4">
        {{ group.label }}
      </v-subheader>

      <v-list-item-group>
        <v-list-item v-for="item in group.values" :key="item.code">
          <v-checkbox
            :value="item.code"
            :label="item.label"
            color="primary"
            class="mt-0 pt-0"
            hide-details
            dense
            @change="$emit('changed')"
          />
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </ApMenu>
</template>

<script>
import ApMenu from './ApMenu'
export default {
  components: {
    ApMenu,
  },
  props: {
    // v-btn props we allow to override
    color: {
      type: String,
      default: undefined,
    },
    height: {
      type: String,
      default: undefined,
    },
    text: {
      type: Boolean,
      default: undefined,
    },
    outlined: {
      type: Boolean,
      default: undefined,
    },
    // Button Custom Properties
    label: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String,
      default: undefined,
    },
    leftIcon: {
      type: String,
      default: undefined,
    },
    rightIcon: {
      type: String,
      default: undefined,
    },
    // Filters Properties
    value: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
