<template>
  <v-menu offset-y>
    <template #activator="{ on }">
      <v-btn v-bind="$props" :icon="!!icon" :aria-label="label" v-on="on">
        <v-icon v-if="icon">
          {{ icon }}
        </v-icon>

        <template v-else>
          <v-icon v-if="leftIcon" left>
            {{ leftIcon }}
          </v-icon>

          {{ label }}

          <v-icon v-if="rightIcon" right>
            {{ rightIcon }}
          </v-icon>
        </template>
      </v-btn>
    </template>

    <slot>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :href="item.href"
          :target="item.href ? '_blank' : undefined"
          :to="item.to"
          :disabled="item.disabled"
          :color="item.disabled ? 'ap-gray' : undefined"
          @click="item.event && $emit(item.event)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon
              v-if="item.icon"
              :color="item.disabled ? 'ap-gray' : undefined"
            >
              {{ item.icon }}
            </v-icon>

            <img
              v-if="item.img"
              :src="item.img"
              :title="item.label"
              :class="{ disabled: item.disabled }"
              width="24"
              height="24"
            />
          </v-list-item-icon>

          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </slot>
  </v-menu>
</template>

<script>
export default {
  props: {
    // v-btn props we allow to override
    color: {
      type: String,
      default: undefined,
    },
    height: {
      type: String,
      default: undefined,
    },
    text: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // Button Custom Properties
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    leftIcon: {
      type: String,
      default: '',
    },
    rightIcon: {
      type: String,
      default: '',
    },
    // Menu Properties
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
img.disabled {
  filter: grayscale(100%);
}
</style>
