<template>
  <v-text-field
    :value="value"
    outlined
    solo
    flat
    clearable
    hide-details
    dense
    clear-icon="$mdi-close"
    append-icon="$mdi-magnify"
    :placeholder="placeholder"
    :loading="isLoading"
    @input="onInput"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    minChars: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    onInput(value) {
      if (value === '' || !value || value.length >= this.minChars) {
        this.$emit('input', value)
      }
    },
  },
}
</script>
